<template>
  <div id="ProductDetail">
    <div v-if="product">
      <!-- <swiper-slide
          v-for="productImgSrc in (variation &&
          !(variation.parent_fields || []).includes('images')
            ? variation
            : product
          ).imageUrl"
          :key="productImgSrc"
        > -->

  
        

      <swiper class="swiper container" :options="swiperOption">

        <swiper-slide
          v-for="productImgSrc in product.imageUrl"
          :key="productImgSrc"
        >
          <div class="img-block">
            <template v-if="zoomEnabled">
              <template v-if="isMobile">
                <img
                  class="img-block-img"
                  :src="productImgSrc"
                  :alt="product.name"
                />
              </template>
              <template v-else>
                <image-zoom
                  class="img-block-img"
                  :regular="productImgSrc"
                  alt="product.name"
                  hover-message="Zoom"
                >
                </image-zoom>
              </template>
            </template>
            <template v-else>
              <img
                class="img-block-img"
                :src="productImgSrc"
                :alt="product.name"
              />
            </template>
          </div>
        </swiper-slide>


      
        
        <swiper-slide
          v-for="variationImgSrc in (product.variations)"
          :key="variationImgSrc"
          v-show='product.variations[0]'
        >
          <div class="img-block">
            <template v-if="zoomEnabled">
              <template v-if="isMobile">
                <img
                  class="img-block-img"
                  :src="variationImgSrc.imageUrl"
                  :alt="product.name"
                />
              </template>
              <template v-else>
                <image-zoom
                  class="img-block-img"
                  :regular="variationImgSrc.imageUrl"
                  alt="product.name"
                  hover-message="Zoom"
                >
                </image-zoom>
              </template>
            </template>
            <template v-else>
              <img
                class="img-block-img"
                :src="variationImgSrc.imageUrl"
                :alt="product.name"
              />
            </template>
          </div>
        </swiper-slide>

        <!-- v-show="(variation && !(variation.parent_fields || []).includes('images') ? variation : product).imageUrl.length > 1 -->

        <div
          v-show="totalProductImages > 1"
          class="swiper-pagination swiper-pagination-white"
          slot="pagination"
        ></div>

        <!-- <div
          v-show="
            (variation && !(variation.parent_fields || []).includes('images')
              ? variation
              : product
            ).imageUrl.length > 1
          "
          class="swiper-button-prev swiper-button-white"
          slot="button-prev"
        ></div> -->

        <div
          v-show="totalProductImages > 1"
          class="swiper-button-prev swiper-button-white"
          slot="button-prev"
        ></div>
        <div
          v-show="totalProductImages > 1"
          class="swiper-button-next swiper-button-white"
          slot="button-next"
        ></div>
      </swiper>
    </div>
    <div id="product-detail" class="fx-main fx-a-i-start">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        :opacity="0.97"
        :z-index="2147480000"
      ></loading>
      <template v-if="product">
        <div class="container">
          <div class="wd-100 fx fx-j-c-f-s fx-wrap mg-1-top">
            
            <div
              v-show="
                variationSelectionType != multiQuantitySelect &&
                totalProductImages != 1
              "
            >
              <div
                class="wd-100 fx-main"
                v-if="totalProductImages >= 2 && totalProductImages <= 5"
              >
            
                <div
                  class="single-thumbnails"
                  slot="button-next"
                  v-for="productImgSrc in product.imageUrl"
                  :key="productImgSrc"
                >
                  <img :src="productImgSrc" />
                </div>


                <div
                  class="single-thumbnails"
                  v-for="variationImgSrc in (product.variations)"
                  :key="variationImgSrc"              
                >
                  
            
                  <img :src="variationImgSrc.imageUrl" />


                </div>

            
              </div>

              <div v-else class="wd-100">
                <carousel
                  id="sync2"
                  class="wd-100"
                  :items="6"
                  :nav="false"
                  :loop="false"
                  :dots="false"
                >
                  <div
                    class="single-thumbnails"
                    v-for="productImgSrc in product.imageUrl"
                    :key="productImgSrc"
                  >
                    <img
                      class="t_slides"
                      :src="productImgSrc"
                      :alt="product.name"
                    />
                  </div>

                  <div
                    class="single-thumbnails"
                    v-for="variationImgSrc in product.variations"
                    :key="variationImgSrc"
                  >
                    <img
                      class="t_slides"
                      :src="variationImgSrc.imageUrl"
                      :alt="product.name"
                    />
                  </div>
                </carousel>
              </div>
            </div>

            <div class="p-photo-name wd-100">
              <div class="fx-center fx-direct-cont wd-100">
                <div
                  class="fx fx-center fx-direct-cont txt-align-center wd-100"
                >
                  <div class="product-detail-name mg-1-top">
                    <h1 class="mg-0 f-size-1-75">{{ product.name }}</h1>
                  </div>
                  <div
                    v-if="product.short_description"
                    class="mg-1-top short-description"
                  >
                    <h2 class="mg-0 f-s-1-em">
                      {{ product.short_description }}
                    </h2>
                  </div>
                  <div
                    class="mg-1-top product-price"
                    v-if="
                      variationSelectionType !== 'multiQuantitySelect' ||
                      !product.variations ||
                      product.variations.length == 0
                    "
                  >
                    <strong
                      v-if="
                        (!$store.state.user.role ||
                          $store.state.user.role == 'STOREKEEPER') &&
                        ((variationSelectionType === 'multiQuantitySelect' &&
                          variationMultiParams &&
                          variationMultiParams.length > 0 &&
                          variationMultiParams.some(
                            (a) => Number(a.quantity) > 0
                          )) ||
                          variation ||
                          !product.variations ||
                          product.variations.length == 0) &&
                        noFloatingBuy
                      "
                      class="main-product-title f-size-1-5"
                    >
                    </strong>

                    <!---------- Sem Plotted Price ----------------------------------->
                    <div v-if="!product.plotted_price">
                      <template
                        v-if="
                          product.price_rules && product.price_rules.length >= 1
                        "
                      >
                        <span v-if="showRootPrice">
                          de
                          <strike>
                            {{
                              (product.price /
                                (1 - product.price_rules[0].amount / 100))
                                | toCurrency
                            }}
                          </strike>
                        </span>
                        <br />
                        <span> por {{ product.price | toCurrency }} </span>
                      </template>
                      <template v-else>
                        {{ product.price | toCurrency }}
                      </template>
                    </div>
                    <!---------- Plotted Price --------------------------------------->
                    <div v-if="product.plotted_price">
                      <span class="price-number">
                        {{ product.price | toCurrency }}
                      </span>

                      <br />
                      <strong> à vista no boleto/PIX com desconto </strong>
                      <br />
                      <span>
                        em {{ max_installments_plotted }}x de
                        {{
                          (product.plotted_price / max_installments_plotted)
                            | toCurrency
                        }}
                        <br />sem juros no cartão
                      </span>
                      <!-- <span class="price-number ">
                        {{ product.plotted_price | toCurrency  }} 
                      </span> -->
                    </div>
                    <!---------- Plotted Price --------------------------------------->
                  </div>
                  <div
                    v-if="
                      product.variations &&
                      product.variations.length &&
                      variationSelectionType === 'boxSelect'
                    "
                    class="variation-box-select fx f-j-c-s-a mg-1-top fx-wrap"
                  >
                    <label
                      v-for="(
                        variationItem, variationIndex
                      ) in product.variations"
                      :key="variationItem.id"
                      class="variation-box-item"
                      :class="variation === variationItem ? 'selected' : ''"
                      :for="'variationRadio_' + variationIndex"
                    >
                      <input
                        type="radio"
                        name="variationRadio"
                        :id="'variationRadio_' + variationIndex"
                        :value="variationItem"
                        v-model="variation"
                      />
                      <span>
                        <b v-if="showPriceOnVariation" class="f-size-1-5">
                          {{ variationItem.price | toCurrency }}
                        </b>
                        {{ variationItem.name }}
                      </span>
                    </label>
                  </div>

                  <div
                    v-if="
                      product.variations &&
                      product.variations.length &&
                      variationSelectionType === 'multiQuantitySelect' &&
                      variationMultiParams &&
                      variationMultiParams.length === product.variations.length
                    "
                    class="
                      variation-multi-select
                      wd-70
                      fx fx-center fx-direct-cont
                      mg-1-top
                    "
                  >
                    <div class="multiple-box">
                      <div class="variation-multi-item fx fx-a-i-c pd-1-em">
                        <strong>Peso</strong>
                        <strong>Preço</strong>
                        <strong class="mf-1-l">Quantidade</strong>
                      </div>

                      <div
                        v-for="(
                          variationItem, variationIndex
                        ) in product.variations"
                        :key="variationItem.id"
                        class="variation-multi-item fx pd-1-em fx-a-i-c"
                      >
                        <span>{{ variationItem.name }}</span>
                        <span class="main-product-title">{{
                          variationItem.price | toCurrency
                        }}</span>
                        <!-- <label
                        class="pd-l-1-em mg-right-1-em"
                        :for="'variationMultiQuantity_' + variationIndex"
                        >Quantidade:</label>-->
                        <div class="dsp-grid gd-tp-c-3-main">
                          <div
                            class="subtract-all mg-0-auto"
                            v-on:click="
                              variationMultiParams[variationIndex].quantity -= 1
                            "
                          >
                            <i>-</i>
                          </div>

                          <input
                            type="number"
                            class="
                              txt-align-center
                              input-quantity
                              f-size-1-25
                              hg-2
                              mg-l-r-1
                            "
                            :name="'variationMultiQuantity_' + variationIndex"
                            :id="'variationMultiQuantity_' + variationIndex"
                            v-model="
                              variationMultiParams[variationIndex].quantity
                            "
                            placeholder="0"
                          />

                          <div
                            class="add-all mg-0-auto"
                            v-on:click="
                              variationMultiParams[variationIndex].quantity += 1
                            "
                          >
                            <i>+</i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="
                      product.variations &&
                      product.variations.length &&
                      variationSelectionType === 'classicSelect' &&
                      noFloatingBuy
                    "
                    class="variation-select mg-1-top"
                  >
                    <select
                      id="sel_var"
                      v-model="variation"
                      class="default-border wd-100"
                    >
                      <option v-if="default_to_empty_variation" :value="null">
                        Selecionar
                      </option>
                      <option
                        v-for="variation in product.variations"
                        :key="variation.id"
                        :value="variation"
                      >
                        {{ variation.name }}
                      </option>
                    </select>
                  </div>

                  <div class="mg-1-top">
                    <template v-if="product.sellable == false">
                      <router-link :to="{ name: 'Help' }" class="btn clickable">
                        Entrar em Contato
                      </router-link>
                    </template>

                    <template v-if="isBuyingEnabled && product.sellable">
                      <div class="fx-center">
                        <div
                          class="subtract"
                          v-on:click="changeProductQuantity('sub')"
                          v-if="
                            (!$store.state.user.role ||
                              $store.state.user.role == 'STOREKEEPER') &&
                            (variation ||
                              !product.variations ||
                              (product.variations.length == 0 &&
                                product.stocked_quantity > 1))
                          "
                        >
                          -
                        </div>
                        <input
                          class="
                            font-input-qtde
                            green-shadow
                            mg-1-bot
                            f-size-1-25
                            hg-2
                            mg-l-r-1
                          "
                          v-if="
                            (!$store.state.user.role ||
                              $store.state.user.role == 'STOREKEEPER') &&
                            (variation ||
                              !product.variations ||
                              product.variations.length == 0)
                          "
                          type="number"
                          v-model="buyAmount"
                          placeholder="qtd"
                        />
                        <div
                          class="add"
                          v-on:click="changeProductQuantity('add')"
                          v-if="
                            (!$store.state.user.role ||
                              $store.state.user.role == 'STOREKEEPER') &&
                            (variation ||
                              !product.variations ||
                              (product.variations.length == 0 &&
                                product.stocked_quantity > 1))
                          "
                        >
                          +
                        </div>
                      </div>

                      <button
                        class="p-detail-btn buy-btn btn"
                        v-if="
                          (!$store.state.user.role ||
                            $store.state.user.role == 'STOREKEEPER') &&
                          ((variationSelectionType === 'multiQuantitySelect' &&
                            variationMultiParams &&
                            variationMultiParams.length > 0 &&
                            variationMultiParams.some(
                              (a) => Number(a.quantity) > 0
                            )) ||
                            variation ||
                            !product.variations ||
                            product.variations.length == 0) &&
                          !noFloatingBuy
                        "
                        v-on:click="addToCart"
                      >
                        {{ buyActionButton }}
                        <i class="fas fa-chevron-right"></i>
                      </button>
                    </template>
                  </div>
                  <button
                    class="btn non-fixed-buy-btn mg-1-top"
                    v-if="
                      (!$store.state.user.role ||
                        $store.state.user.role == 'STOREKEEPER') &&
                      ((variationSelectionType === 'multiQuantitySelect' &&
                        variationMultiParams &&
                        variationMultiParams.length > 0 &&
                        variationMultiParams.some(
                          (a) => Number(a.quantity) > 0
                        )) ||
                        variation ||
                        !product.variations ||
                        product.variations.length == 0) &&
                      noFloatingBuy &&
                      product.sellable == true
                    "
                    v-on:click="addToCart"
                  >
                    {{ buyActionButton }}
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="mg-2-top product-detail-freight"
            v-if="showShippingOnProductDetail"
          >
            <div
              class="
                wd-50
                pd-v-2-h-l-1 pd-1-em
                green-shadow
                mg-0-auto
                freight-block
              "
            >
              <div
                class="cart-checkout-options wd-100 fx fx-direct-cont mg-1-bot"
              >
                <div class="txt-align-center">
                  <span>Calcular o Frete do Produto</span>
                </div>
              </div>
              <the-mask
                mask="#####-###"
                type="text"
                :masked="true"
                name="business_address.zipcode"
                v-model="zipcode"
                class="default-border mg-1-bot"
                placeholder="Calcule seu frete"
              ></the-mask>

              <div
                class="btn freight-btn wd-100"
                v-on:click="calculateShipping(zipcode)"
              >
                Calcular Frete
              </div>

              <div
                v-for="listMethods in this.shippingMethodsList"
                :key="listMethods.shipping_method"
              >
                <template
                  v-if="
                    listMethods.shipping_method && listMethods.price < 91239124
                  "
                >
                  <div class="shipping-method-list mg-1-top pd-1-em">
                    <div v-if="listMethods.shipping_method">
                      {{ listMethods.shipping_method | toShipping }}
                    </div>
                    <div v-if="listMethods.days" class="days">
                      {{ listMethods.days }} dias
                    </div>
                    <div>{{ listMethods.price | toCurrency }}</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <!-- <div v-if="!$store.state.user.role || $store.state.user.role == 'STOREKEEPER'">
              <the-mask
                mask="#####-###"
                type="text"
                :masked="true"
                name="business_address.zipcode"
                v-model="zipcode"
                class="default-border mg-1-bot"
                placeholder="Calcule seu frete"
              ></the-mask>

              <div v-if="enabled_shipping_methods.includes('go_fetch')" class="mg-1-bot">
                <input
                  type="radio"
                  name="shipping_method"
                  value="Retirar na Loja"
                  v-model="shipping_method"
                  id="radio-sedex"
                />

                <label for="go_fetch">Retirar na Loja</label>
              </div>

              <div v-if="enabled_shipping_methods.includes('sedex')" class="mg-1-bot">
                <input
                  type="radio"
                  name="shipping_method"
                  value="sedex"
                  v-model="shipping_method"
                  id="radio-sedex"
                />
                <label for="radio-sedex">Sedex</label>
              </div>
              <div v-if="enabled_shipping_methods.includes('pac')" class="mg-1-bot">
                <input
                  type="radio"
                  name="shipping_method"
                  value="pac"
                  v-model="shipping_method"
                  id="radio-pac"
                />
                <label for="radio-pac">PAC</label>
              </div>
              <div v-if="delivery_days || shipping_price" class="mg-1-bot fx fx-direct-cont">
                <span class="mg-1-bot">Resultados:</span>

                <span class="mg-1-bot" v-if="delivery_days">Dias para Entrega: {{ delivery_days }}</span>

                <span v-if="shipping_price">Preço da Entrega: {{ shipping_price | toCurrency }}</span>
              </div>
            </div> -->
          <div
            class="
              wd-100
              pd-v-2-h-l-1 pd-1-em
              green-shadow
              mg-0-auto
              freight-block
              mg-2-top
            "
          >
            <div
              class="cart-checkout-options wd-100 fx fx-direct-cont mg-1-bot"
            >
              <div class="txt-align-center">
                <span>Descrição do Produto</span>
              </div>
            </div>

            <div class="fx-center p-desc container mg-1-top">
              <div class="fx fx-center wd-100 p-description fx-direct-cont">
                <p
                  class="wd-100 pre-line"
                  v-if="variation && variation.description"
                  v-html="variation.description || ''"
                ></p>
                <p
                  class="wd-100 pre-line"
                  v-html="product.description || ''"
                ></p>
              </div>
            </div>
          </div>
          <template
            v-if="
              product.related_files || (variation && variation.related_files)
            "
          >
            <div
              class="
                default-border
                fx
                col-md-2
                mg-right-2em
                manage-img
                fx-wrap
                mg-top-2
                fx-direct-cont
              "
              v-for="relatedFile in (variation &&
              !(variation.parent_fields || []).includes('related_files')
                ? variation.related_files || []
                : product.related_files || []
              ).filter((a) => a.published)"
              :key="relatedFile.id"
            >
              <p>{{ relatedFile.name }}</p>
              <p
                v-if="relatedFile.description && relatedFile.description.length"
              >
                {{ relatedFile.description }}
              </p>
              <audio controls v-if="relatedFile.mime.startsWith('audio')">
                <source
                  :src="
                    getFileUrl(
                      product.id,
                      variation ? variation.id : null,
                      relatedFile.id
                    )
                  "
                  :type="relatedFile.mime"
                />
                O seu navegador não suporta esse tipo de áudio!
              </audio>
              <video
                width="320"
                height="240"
                controls
                v-else-if="relatedFile.mime.startsWith('video')"
              >
                <source
                  :src="
                    getFileUrl(
                      product.id,
                      variation ? variation.id : null,
                      relatedFile.id
                    )
                  "
                  :type="relatedFile.mime"
                />
                O seu navegador não suporta esse tipo de vídeo!
              </video>
              <a
                v-if="relatedFile.downloadable"
                :download="relatedFile.name"
                :href="
                  getFileUrl(
                    product.id,
                    variation ? variation.id : null,
                    relatedFile.id
                  )
                "
                >Baixar Arquivo</a
              >
            </div>
          </template>

          <!-- TODO: Breadcrumb com Feature Flags -->
          <!-- <div class="mg-2" v-if="product.categories">
            <span>menu / {{ (product.categories || []).map(c => c.name).join(', ') }}</span>
          </div>-->

          <div
            class="collapse-btn"
            v-for="prule in product.price_rules"
            :key="prule.id"
          >
            <div v-if="prule.compiled_price">
              <span
                class
                v-if="prule.max_quantity && prule.max_quantity != Infinity"
                >Compre de {{ prule.min_quantity }} até
                {{ prule.max_quantity }} unidades</span
              >
              <span v-else
                >Compre a partir de {{ prule.min_quantity }} unidades</span
              >
              <span>e pague {{ prule.compiled_price | toCurrency }}</span>
            </div>
          </div>

          <div class="fixed-buttons fixed container">
            <div class="fx fx-main fx-j-c-s-b fx-a-i-cent">
              <div
                v-if="
                  product.variations &&
                  product.variations.length &&
                  variationSelectionType === 'classicSelect' &&
                  !noFloatingBuy
                "
                class="variation-select"
              >
                <select
                  id="sel_var"
                  v-model="variation"
                  class="default-border wd-100"
                >
                  <option v-if="default_to_empty_variation" :value="null">
                    Selecionar
                  </option>
                  <option
                    v-for="variation in product.variations"
                    :key="variation.id"
                    :value="variation"
                  >
                    {{ variation.name }}
                  </option>
                </select>
              </div>
              <template v-if="isBuyingEnabled && product.sellable">
                <!-- <div>
                  <input
                    v-if="(!$store.state.user.role || $store.state.user.role == 'STOREKEEPER') && (variation || !product.variations || product.variations.length == 0)"
                    type="number"
                    v-model="buyAmount"
                    placeholder="qtd"
                  />
                </div>-->

                <button
                  class="p-detail-btn buy-btn"
                  v-if="
                    (!$store.state.user.role ||
                      $store.state.user.role == 'STOREKEEPER') &&
                    ((variationSelectionType === 'multiQuantitySelect' &&
                      variationMultiParams &&
                      variationMultiParams.length > 0 &&
                      variationMultiParams.some(
                        (a) => Number(a.quantity) > 0
                      )) ||
                      variation ||
                      !product.variations ||
                      product.variations.length == 0) &&
                    !noFloatingBuy
                  "
                  v-on:click="addToCart"
                >
                  {{ buyActionButton }}
                  <i class="fas fa-chevron-right"></i>
                </button>
              </template>

              <template
                v-if="
                  $store.state.user.role == 'SUPPLIER' &&
                  $store.state.user.id == product.supplier_id
                "
              >
                <button
                  class="p-detail-btn buy-btn"
                  v-on:click="
                    $router.push({
                      name: 'manage-product',
                      query: { product_id: product.id },
                    })
                  "
                >
                  Editar Produto
                  <i class="fas fa-chevron-right"></i>
                </button>
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.owl-carousel img {
  width: auto !important;
}

div.owl-carousel > .owl-carousel > .owl-stage {
  padding: 0 !important;
}

table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.swiper-button-next.swiper-button-white,
.swiper-button-prev.swiper-button-white {
  -webkit-transition: box-shadow 0.1s ease-out;
  transition: box-shadow 0.1s ease-out;
  // box-shadow: 0 7px 16px 0 rgb(0 0 0 / 20%), 0 1px 3px 0 rgb(0 0 0 / 10%);
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
  border-radius: 4px;
  height: 80px;
  margin-top: -40px;
  top: 50%;
  width: 40px;
  background: #f9fbfc;
}

.owl-stage {
  display: flex !important;
}

.single-thumbnails {
  width: 4em;
  height: 4em;
  position: relative;
  background: white;
  display: flex;
}

.single-thumbnails img {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: auto;
  left: 50%;
  max-height: 100%;
  max-width: 100%;

  padding: 0.6em;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}

.p-description table {
  max-width: 100% !important;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 2em 0;
  float: left;
}

.multiple-box {
  width: 100%;
}

.variation-multi-item.fx.pd-1-em.fx-a-i-c input {
  width: 30%;
  margin: 0 auto;
  height: 2em;
}

.variation-multi-item.fx.pd-1-em.fx-a-i-c {
  grid-template-columns: 30% 15% 50% !important;
}

.p-description table th,
.p-description table td,
.p-description table tr {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1em;
}

.product-detail-freight span {
  width: 15em !important;
}

.fit-product-image {
  width: 30vw;
}

@media (max-width: 600px) {
  .input-quantity {
    width: 50px !important;
  }
  .hg-2 {
    height: 2em;
  }
  .fit-product-image {
    width: 100vw;
  }
}
.add,
.add-all {
  cursor: pointer;
  color: white;
  display: inline-block;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  background: black;
  height: 100%;
  border-radius: 100%;
  width: 1.5em;
  height: 1.5em;
  align-items: center;
  display: flex;
  justify-content: center;
}
.subtract,
.subtract-all {
  cursor: pointer;
  color: white;
  display: inline-block;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  background: black;
  height: 100%;
  border-radius: 100%;
  width: 1.5em;
  height: 1.5em;
  align-items: center;
  display: flex;
  justify-content: center;
}

.variation-multi-item input {
  margin-top: auto !important;
}

.product-description {
  padding-bottom: 5em;
}
.product-name-price {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24), 0 4px 4px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.24),
    0 4px 4px 0 rgba(0, 0, 0, 0.12);
}
.p-photo-name {
  display: flex;
  justify-content: flex-start;
}
.showTransitionProductDetails {
  opacity: 1;
  transition: opacity 0.5s linear;
}
.hideTransitionProductDetails {
  opacity: 0;
  pointer-events: none;
}
.hide {
  display: none;
}
.pd-fix-bar {
  padding: 0.2em;
}
.circle {
  height: 10em;
  width: 10em;
  border-radius: 100%;
  position: relative;
}
.variation-box-select {
  width: 60%;
}
.variation-box-select input {
  display: none;
}
.variation-box-item {
  border: 1px solid black;
  padding: 1em;
  cursor: pointer;
}
.variation-box-item.selected {
  background-color: black;
  color: white;
  border: 1px solid white;
}
@media (max-width: 500px) {
  .subtract,
  .subtract-all,
  .add,
  .add-all {
    width: 1.25em;
    height: 1.25em;
  }

  .variation-box-select {
    width: 100%;
  }
}

p.wd-100.pre-line {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.variation-multi-item.fx.pd-1-em.fx-a-i-c {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.font-input-qtde {
  text-align: center;
  width: 30%;
  height: 2em;
}
.font-input-qtde:focus .font-input-qtde:hover {
  text-align: center;
  width: 40%;
}

@media (max-width: 500px) {
  .variation-multi-item.fx.pd-1-em.fx-a-i-c {
    grid-template-columns: 5em 6em 1fr !important;
  }

  .mf-1-l {
    margin-left: 0;
  }

  .variation-multi-select .pd-1-em {
    padding: 0 !important;
    margin-top: 1em;
  }
  .f-size-1-25 {
    font-size: 1em;
  }
}
</style>

<script>
import router from "@/router";
import Product from "@/services/product.js";
import User from "@/services/user.js";
import HeaderBuilder from "@/services/header-builder.js";
import { apiBaseUrl } from "@/services/api.js";
import util from "@/services/util";
import axios from "axios";
import { TheMask } from "vue-the-mask";
import Cart from "@/services/cart.js";
import { debounce } from "debounce";
import boolean from "boolean";
import Mobile from "@/services/mobile.js";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Loading from "vue-loading-overlay";
import Features from "@/services/features";
import carousel from "vue-owl-carousel2";

export default {
  name: "ProductDetail",
  template: "#product-detail",
  components: {
    "the-mask": TheMask,
    Loading,
    Swiper,
    SwiperSlide,
    carousel,
  },
  data: function () {
    return {
      isMobile: Mobile.isMobile(),
      default_to_empty_variation: boolean(
        process.env.VUE_APP_DEFAULT_TO_EMPTY_VARIATION
      ),
      variationSelectionType: process.env.VUE_APP_VARIATION_SELECTION_TYPE,
      showShippingOnProductDetail: boolean(
        process.env.VUE_APP_SHOW_SHIPPING_ON_PRODUCT_DETAIL
      ),

      showPriceOnVariation: boolean(
        process.env.VUE_APP_SHOW_PRICE_ON_VARIATION
      ),
      loadIndex: 0,
      cepLoadIndex: 0,
      product: null,
      buyAmount: 1,
      variation: null,
      zipcode: null,
      delivery_days: null,
      shipping_price: null,
      shipping_method: null,
      stockedQuantity: null,
      buildShipping: [],
      enabled_shipping_methods: [],
      variationMultiParams: null,
      isBuyingEnabled: false,
      zoomEnabled: process.env.VUE_APP_ZOOM_ENABLED,
      assumeSingleSupplier: boolean(process.env.VUE_APP_ASSUME_SINGLE_SUPPLIER),
      enable_thumbails: boolean(process.env.VUE_APP_SHOW_THUMBNAILS),
      noFloatingBuy: boolean(process.env.VUE_APP_NO_FLOATING_BUY),
      buyActionButton: process.env.VUE_APP_ACTION_BUTTON || "Comprar",
      swiperOption: {
        spaceBetween: 30,
        loop: true,
        effect: "fade",
        thumbs: true,
        pagination: {
          type: "fraction",
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      shippingMethodsList: [],
      max_installments_plotted: null,
      totalProductImages: null,
    };
  },
  metaInfo() {
    return !this.product
      ? {}
      : {
          title: `${this.product.name} - ${util.defaultTitle()}`,
          meta: [
            {
              vmid: "description",
              name: "description",
              content: this.product.short_description,
            },
            { vmid: "og:title", name: "og:title", content: this.product.name },
            {
              vmid: "og:description",
              name: "og:description",
              content: util.truncateBySentences(
                this.product.name + " - " + this.product.short_description,
                2
              ),
            },
            {
              vmid: "og:image",
              name: "og:image",
              content: (this.product.imageUrl || [])[0],
            },
          ],
        };
  },
  mounted() {
    
    this.loadIndex++;
    Features.get()
      .then((features) => {
this.isBuyingEnabled = features.buying && features.buying.enabled;
        this.shipping_method = Object.entries(features.shipping_methods)
          .filter((e) => boolean(e[1].default))
          .map((e) => e[0])[0];
        this.enabled_shipping_methods = Object.entries(
          features.shipping_methods
        )
          .filter((e) => boolean(e[1].enabled))
          .map((e) => e[0]);
        this.max_installments_plotted =
          features.payment_methods && features.payment_methods.credit_card
            ? Number(
                features.payment_methods.credit_card
                  .maximum_installments_plotted
              )
            : null;
      })
      .finally(() => {
        this.loadIndex--;
      });

    this.loadIndex++;
    Product.get(this.$route.params.product_id, {
      forAmount: this.buyAmount,
    })
      .then((product) => {
        this.product = product;

        this.stockedQuantity = this.product.stocked_quantity;

        if (product.variations && product.variations.length) {
          product.variations = product.variations.filter(
            (v) => Number(v.stocked_quantity) > 0
          );
          this.variationMultiParams = Array(product.variations.length)
            .fill(1)
            .map((x) => {
              return {
                quantity: null,
              };
            });
          this.variation =
            !this.default_to_empty_variation && product.variations.length
              ? product.variations[0]
              : null;
        }
        this.product.price_rules = (this.product.price_rules || []).sort(
          (x) => -x.min_quantity
        );
        this.product.price_rules.forEach((prule) => {
          if (!prule.enabled) {
            return;
          }
          Product.get(this.$route.params.product_id, {
            forAmount: prule.min_quantity,
          }).then((ruledP) => {
            prule.compiled_price = ruledP.price;
            this.$forceUpdate();
          });
        });
        Product.get(this.$route.params.product_id).then((originalProduct) => {
          this.product.real_price = originalProduct.price;
          this.$forceUpdate();
        });
        if (!this.product.supplier) {
          User.get(this.product.supplier_id).then((supplier) => {
            this.product.supplier = supplier;
            // Force-update needed because we are editing a sub-property :(
            this.$forceUpdate();
          });
        }

        this.countProductImages(product.images.length, product.variations);
      })
      .finally(() => {
        this.loadIndex--;
      });

    User.self().then((user) => {
      if (!user) {
        return;
      }
      if (!this.zipcode) {
        this.zipcode = user.business_address
          ? user.business_address.zipcode
          : "";
      }
    });
  },
  methods: {
    countProductImages: function (receiveProduct, receiveVariations) {
      

      

      receiveVariations.forEach((singleVariation) => {
        this.totalProductImages += singleVariation.images.length;
      });

      this.totalProductImages += receiveProduct;

      

      return this.totalProductImages;
    },
    getFileUrl: function (productId, variationId, fileId) {
      return (
        `${apiBaseUrl}/api/product/${productId}/related_file/${fileId}?apartment=${process.env.VUE_APP_TENANT_ID}` +
        (variationId ? `&variation_id=${variationId}` : "")
      );
    },
    changeProductQuantity: function (action) {
      if (action == "add") {
        if (this.product.stocked_quantity > this.buyAmount) {
          this.buyAmount++;
        }
      } else {
        if (this.buyAmount == 1) this.buyAmount = 1;
        else this.buyAmount--;
      }
    },
    addToCart: function () {
      var formattedPrice = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(this.product.price / 100);
      var finalPrice = formattedPrice.replace("R$", "");
      finalPrice = finalPrice.replace(" ", "");

      if (this.$gtm.enabled()) {
        

        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
          event: "add_to_cart",
          ecommerce: {
            currency: "BRL",
            value: finalPrice,
            items: [
              {
                item_id: this.product.id,
                item_name: this.product.name,
                affiliation: process.env.VUE_APP_TENANT_ID,
                currency: "BRL",
                item_category: !this.product.categories[0]
                  ? "sem categoria"
                  : this.product.categories[0].name,
                price: finalPrice,
                quantity: this.buyAmount,
              },
            ],
          },
        });
      }

      this.loadIndex++;
      let promie;
      const isMulti =
        this.variationSelectionType === "multiQuantitySelect" &&
        this.variationMultiParams;
      if (isMulti) {
        promie = this.variationMultiParams
          .map((vp, vi) => {
            if (!vp || Number(vp.quantity) <= 0) {
              return () => Promise.resolve();
            }
            return () => {
              return Cart.add(
                this.product.id,
                vp.quantity,
                this.product.variations[vi]
              );
            };
          })
          .reduce(
            (p, x) =>
              p.then((_) => {
                return x();
              }),
            Promise.resolve()
          );
      } else {
        promie = Cart.add(this.product.id, this.buyAmount, this.variation);
      }
      const buyAmountLocal = isMulti
        ? this.variationMultiParams
            .map((v) => Number(v.quantity || 0))
            .reduce((a, b) => Number(a || 0) + Number(b || 0), 0)
        : this.buyAmount;
      promie
        .then(
          (response) => {
            this.$root.$emit("cartUpdate");
            this.$swal({
              title: "Produto Adicionado!",
              text: `${buyAmountLocal} unidade${
                buyAmountLocal > 1 ? "s" : ""
              } de ${this.product.name} ${
                buyAmountLocal > 1 ? "foram" : "foi"
              } adicionada${buyAmountLocal > 1 ? "s" : ""} ao seu carrinho!`,
              icon: "success",
              buttons: {
                nope: {
                  text: "Comprar mais",
                  value: false,
                  visible: true,
                  className: "",
                  closeModal: true,
                },
                cart: {
                  text: "Ir para carrinho",
                  value: true,
                  visible: true,
                  className: "",
                  closeModal: true,
                },
              },
            }).then((result) => {
              if (!result) {
                router.go(-1);
              }
              router.push({
                name: "cart",
              });
            });
            return;
          },
          (error) => {
            this.$notify({
              type: "error",
              duration: 24000,
              title: "Erro ao adicionar no Carrinho!",
              text: util.stringifyAxiosError(error),
            });
          }
        )
        .finally(() => {
          this.loadIndex--;
        });
    },

    calculateShipping: function () {
      this.shippingMethodsList = [];
      this.cepLoadIndex++;
      this.zipcode = this.zipcode.replace(/\D/, "");

      this.enabled_shipping_methods.forEach((shippingMethod) => {
        axios
          .get(
            `${apiBaseUrl}/api/product/${this.product.id}/shipping?zipcode=${
              this.zipcode
            }&shipping_method=${shippingMethod}&init_price=${
              this.product.price * this.buyAmount
            }
             ${this.variation ? "&variation_id=" + this.variation.id : ""}`,
            {
              headers: HeaderBuilder.build(),
            }
          )
          .then(
            (response) => {

              if (shippingMethod == "express_delivery") {
                if (response.data.price == 0) {
                  shippingMethod = "Entrega Grátis";
                } else {
                  shippingMethod = "Courier";
                }
              }
              this.buildShipping = {
                shipping_method: shippingMethod,
                price: response.data.price,
                days: response.data.delivery_days,
              };
              //}

              this.shippingMethodsList.push(this.buildShipping);

              this.shipping_price = response.data.price;
              this.delivery_days = response.data.delivery_days;
            },
            (error) => {
              this.shipping_price = null;
              this.delivery_days = null;
            }
          )
          .finally(() => {
            this.cepLoadIndex--;
          });

        // Cart.shipping(this.zipcode, shippingMethod).then((response) => {

        // });
      });
    },

    // cepera: debounce(
  },
  computed: {
    isLoading: function () {
      return this.loadIndex > 0;
    },
    isCepLoading: function () {
      return this.cepLoadIndex > 0;
    },
    isShowingDetailProductBarComputedMobile: function () {
      return this.isMobile ? "showTransitionProductDetails" : "hide";
    },
    isShowingDetailProductBarComputedDesktop: function () {
      return this.isMobile ? "hide" : "";
    },
    showRootPrice: function () {
      return this.product.root_price_enabled && this.product.price;
    },
  },
  watch: {
    // zipcode: function () {
    //   this.cepera();

    // },
    // shipping_method: function () {

    //   this.cepera();
    // },

    buyAmount: debounce(function () {
      if (!this.product) {
        return;
      }
      // this.loadIndex++;
      Product.get(this.product.id, {
        forAmount: this.buyAmount,
        variation_id: (this.variation || {}).id,
      }).then((product) => {
        this.product.price = product.price;
      });
      // .finally(() => this.loadIndex--);
    }, 200),
    variation: function () {
      if (!this.product) {
        return;
      }
      this.loadIndex++;
      Product.get(this.product.id, {
        forAmount: this.buyAmount,
        variation_id: (this.variation || {}).id,
      })
        .then((product) => {
          this.product.price = product.price;
        })
        .finally(() => {
          this.loadIndex--;
        });
      this.cepera();
    },
  },
};
</script>